<template>
  <div class="bar-chart svg-container" ref="barChart">
    <svg
      :width="svgWidth + svgPaddingX"
      :height="svgHeight + svgPaddingY"
      v-if="redrawToggle"
    >
      <text x="0" y="0" font-size="14" :transform="`translate(10, 20)`">
        {{ keyName[0] }}
      </text>
      <text
        x="0"
        y="0"
        font-size="14"
        :transform="`translate(${svgWidth + svgPaddingX - 30}, ${
          svgHeight + svgPaddingY - 10
        })`"
      >
        {{ keyName[1] }}
      </text>
      <g
        x="0"
        y="0"
        :transform="`translate(${svgPaddingX - 50},${svgPaddingY - 20})`"
        ref="dataRect"
      >
        <rect
          v-for="(item, key, index) in data.state"
          :key="'statusData' + key + index"
          class="bar-positive"
          fill="#48EBDC"
        />
        <text
          v-for="(item, key, index) in data.state"
          class="statusDataText"
          text-anchor="middle"
          font-size="14"
          :key="'statusDataText' + key + index"
        ></text>
      </g>
      <g
        x="0"
        y="0"
        :transform="`translate(${svgPaddingX - 50},${svgPaddingY - 20})`"
        ref="modalRect"
      >
        <rect
          v-for="(item, key, index) in data.state"
          :key="'ModalStatusData' + key + index"
          class="bar-positive"
          fill="transparent"
          :height="svgHeight"
          @click="showModal(key)"
          style="cursor: pointer"
        />
      </g>
      <g
        x="0"
        y="0"
        ref="xAxis"
        font-size="14"
        :transform="`translate(${svgPaddingX - 50},${
          svgPaddingY - 20 + svgHeight
        })`"
      ></g>
      <g
        x="0"
        y="0"
        ref="yAxis"
        font-size="14"
        :transform="`translate(${svgPaddingX - 50},${svgPaddingY - 20})`"
      ></g>
    </svg>
    <div class="modal fade cms-style" tabindex="-1" id="edit" ref="infoModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-medium">
              {{
                type === "device"
                  ? $t("__machineHealth")
                  : $t("__networkAbnormal")
              }}
              <span class="ps-2">{{ modalKey }}</span>
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-borderless table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Timestamp</th>
                    <th scope="col">Parameter</th>

                  </tr>
                </thead>
                <tbody>
                  <template v-if="modalKey === '06'">
                    <tr v-for="(data, index) in deviceSixData" :key="'deviceSixData' + index" class="align-top">
                      <td class="ps-2 pe-3">{{ data.id }}</td>
                      <td class="ps-2 pe-3">{{ data.timestamp }}</td>
                      <td class="ps-2" style="word-break: break-all;">{{ data.list.join('、') }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(data, index) in modalDate" :key="'modalDate' + index" class="align-top">
                      <td class="ps-2 pe-3">{{ data.serial_id }}</td>
                      <td class="ps-2 pe-3">{{ data.timestamp }}</td>
                      <td class="ps-2" style="word-break: break-all;">{{ data.parameter }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { select } from "d3-selection";
import { mapState, mapMutations } from "vuex";
import { scaleBand, scaleLinear } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { Modal } from "bootstrap";

import "d3-transition";

export default {
  name: "BarChart",
  props: ["yKey", "svgPaddingX", "svgPaddingY", "data", "type", "keyName", "deviceAllCount"],
  data() {
    return {
      svgWidth: 0,
      svgHeight: 150,
      redrawToggle: true,
      windowWidth: window.innerWidth,
      minValue: 0,
      modalKey: null,
      modalDate: null,
      bootstrap_modal: null,
      device: null
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    maxValue() {
      return this.deviceAllCount;
    },
    xScale() {
      return scaleBand()
        .domain(this.yKey)
        .range([0, this.svgWidth])
        .paddingInner(0.5)
        .paddingOuter(0.2)
        .round(true);
    },
    yScale() {
      return scaleLinear()
        .range([0, this.svgHeight])
        .domain([this.maxValue, this.minValue]);
    },
    xAxis() {
      return axisBottom(this.xScale).tickValues(this.yKey);
    },
    yAxis() {
      return axisLeft(this.yScale).ticks(this.maxValue > 5 ? 5 : this.maxValue);
    },
    deviceSixData () {
      const vm = this;
      const sortData = []
      if (vm.type === "device") {
        const listData =  vm.data.list
        for (const [key, data] of Object.entries(listData)) {
          if (data.records.length > 1) {
            const id = data.serial_id
            const timestamp =  data.records[0].record_at.replace(/\s+|-|:/g, '')
            const list = []
            data.records.forEach(data => {
              if ( list.length > 9) return
              // 一個月毫秒 2629800000
              if (new Date().getTime() - new Date(data.record_at.replace(/-/g, '/')).getTime() < 2629800000) {
                list.push(data.param)
              }
            });
            if (list.length > 0) {
              sortData.push({
                id,
                timestamp,
                list
              })
            }
          }
        }

        return sortData.length > 0 ? sortData :  []
      }
      return sortData
    }
  },
  watch: {
    data(data) {
      this.$nextTick(function () {
        this.animateLoad();
      })
    }
  },
  methods: {
    ...mapMutations([
      "Loading",
      "Loaded"
    ]),
    showModal(key) {
      const vm = this;
      vm.Loading()
      vm.modalKey = key;
      vm.modalDate =
        vm.type === "device" ? vm.data.state[key].device : vm.data.state[key];

      vm.$nextTick(function () {
        vm.bootstrap_modal = new Modal(vm.$refs.infoModal);
        vm.bootstrap_modal.show();
        vm.Loaded()
      });
    },
    animateLoad() {
      select(this.$refs.dataRect)
        .selectAll("rect")
        .data(Object.keys(this.data.state))
        .attr("x", (d) => this.xScale(d))
        .attr("y", (d) => {
          var yValue;
          if (this.type === "device")
            yValue = d === '06' ? this.yScale(this.deviceSixData.length) : this.yScale(this.data.state[d].count);
          if (this.type === "network")
            yValue = this.yScale(this.data.state[d].length);
          return yValue;
        })
        .attr("width", this.xScale.bandwidth())
        .attr("height", (d) => {
          var height;
          if (this.type === "device")
            height = this.svgHeight - (d === '06' ? this.yScale(this.deviceSixData.length) : this.yScale(this.data.state[d].count));
          if (this.type === "network")
            height = this.svgHeight - this.yScale(this.data.state[d].length);
          return height;
        });

      select(this.$refs.modalRect)
        .selectAll("rect")
        .data(Object.keys(this.data.state))
        .attr("x", (d) => this.xScale(d))
        .attr("width", this.xScale.bandwidth());

      select(this.$refs.barChart)
        .selectAll(".statusDataText")
        .data(Object.keys(this.data.state))
        .text((d) => {
          var text;
          if (this.type === "device"){
            text = d === '06'
              ? this.deviceSixData.length ? this.deviceSixData.length : ''
              : this.data.state[d].count;
          }
          if (this.type === "network") text = this.data.state[d].length;
          return text;
        })
        .attr("x", (d) => this.xScale(d) + this.xScale.bandwidth() / 2)
        .attr("y", (d) => {
          var yValue;
          if (this.type === "device")
            yValue = this.yScale(d === '06' ? this.deviceSixData.length : this.data.state[d].count) - 5;
          if (this.type === "network")
            yValue = this.yScale(this.data.state[d].length) - 5;
          return yValue;
        });

      select(this.$refs.xAxis).call(this.xAxis);
      select(this.$refs.yAxis).call(this.yAxis);
    },
    addResizeListener() {
      window.addEventListener("resize", () => {
        const vm = this;
        if (vm.windowWidth === window.innerWidth) return;
        vm.redrawToggle = false;
        setTimeout(() => {
          vm.redrawToggle = true;
          vm.$nextTick(function () {
            this.svgWidth = Number(
              this.$refs.barChart.offsetWidth - this.svgPaddingX
            );
            this.animateLoad();
          });
        }, 0);
      });
    }
  },
  created() {},
  mounted() {
    this.$nextTick(function () {
      this.svgWidth = Number(
        this.$refs.barChart.offsetWidth - this.svgPaddingX
      );
      this.animateLoad();
      this.addResizeListener();
    });
  },
};
</script>

<style lang="scss" scoped>
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 1%;
  vertical-align: top;
  overflow: hidden;
  &::v-deep text {
    font-size: 14px;
  }
}
</style>
