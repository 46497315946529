<template>
  <div class="bar-chart svg-container" ref="barChart">
    <div class="mb-3 mt-2">
      <span
        class="pe-2 ps-sm-3 fw-bold d-inline-block"
        v-for="(item, key) in chartColor"
        :key="key"
      >
        <span :style="{ color: item }">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
            <circle cx="7" cy="7" r="7"/>
          </svg>
        </span>
        <span>{{ $t("__" + key) }}</span>
        <span class="ps-1">{{ data.statistics[key] }}</span>
      </span>
    </div>
    <svg
      :width="svgWidth + svgPaddingX"
      :height="svgHeight + svgPaddingY"
      v-if="redrawToggle"
    >
      <text x="0" y="0" font-size="14" :transform="`translate(10, 20)`">{{keyName}}</text>
      <g
        x="0"
        y="0"
        ref="processed"
        :transform="`translate(${svgPaddingX - 30},${svgPaddingY - 20})`"
      >
        <template v-for="(item, key, index) in data.data">
          <rect
            :key="'processed' + index"
            :fill="chartColor.processed"
          />
          <text
            class="processedText"
            text-anchor="middle"
            font-size="14"
            :key="'processedText' + index"
          >
          </text>
        </template>
      </g>
      <g
        ref="processing"
        x="0"
        y="0"
        :transform="`translate(${svgPaddingX - 30},${svgPaddingY - 20})`"
      >
        <template v-for="(item, key, index) in data.data">
          <rect
            :key="'processing' + index"
            :fill="chartColor.processing"
          />
          <text
            class="processingText"
            text-anchor="middle"
            font-size="14"
            :key="'processingText'+ index"
          >
          </text>
        </template>
      </g>
      <g
        x="0"
        y="0"
        ref="xAxis"
        :transform="`translate(${svgPaddingX - 30},${
          svgPaddingY - 20 + svgHeight
        })`"
      ></g>
      <g
        x="0"
        y="0"
        ref="yAxis"
        :transform="`translate(${svgPaddingX - 30},${
          svgPaddingY - 20
        })`"
      ></g>
    </svg>
  </div>
</template>

<script>
import { select } from 'd3-selection'
import { scaleBand, scaleLinear } from 'd3-scale'
import { axisBottom, axisLeft } from 'd3-axis'
import i18n from '@/lang/lang.js'
import 'd3-transition'

export default {
  name: 'StackedBarChart',
  props: ['yKey', 'svgPaddingX', 'svgPaddingY', 'data', 'type', 'keyName', 'chartColor'],
  data () {
    return {
      chartData: this.data,
      svgWidth: 0,
      svgHeight: 150,
      redrawToggle: true,
      windowWidth: window.innerWidth,
      minValue: 0
    }
  },
  computed: {
    maxValue () {
      return this.data.count
    },
    xScale () {
      return scaleBand()
        .domain(this.yKey)
        .range([0, this.svgWidth])
        .paddingInner(0.5)
        .paddingOuter(0.2)
        .round(true)
    },
    yScale () {
      return scaleLinear()
        .range([0, this.svgHeight])
        .domain([
          this.maxValue,
          this.minValue
        ])
    },
    xAxis () {
      return axisBottom(this.xScale)
        .tickValues(this.yKey)
        .tickFormat(d => {
          var keyName
          switch (d) {
            case '1':
              keyName = i18n.t('__breathe')
              break
            case '2':
              keyName = i18n.t('__getup')
              break
            case '3':
              keyName = i18n.t('__longInBed')
              break
            case '4':
              keyName = i18n.t('__leaveTheBed')
              break
            case '5':
              keyName = i18n.t('__restless')
              break
          }
          return keyName
        })
    },
    yAxis () {
      return axisLeft(this.yScale)
        .ticks(this.maxValue)
    }
  },
  watch: {
    data () {
      this.redrawToggle = false
      setTimeout(() => {
        this.redrawToggle = true
        this.$nextTick(function () {
          this.animateLoad()
        })
      }, 0)
    }
  },
  methods: {
    animateLoad () {
      select(this.$refs.processing)
        .selectAll('rect')
        .data(Object.keys(this.data.data))
        .attr('x', d => this.xScale(d))
        .attr('y', d => {
          if (this.svgHeight - this.yScale(this.data.data[d].processed) > 0) {
            return this.yScale(this.data.data[d].processing) - (this.svgHeight - this.yScale(this.data.data[d].processed))
          } else {
            return this.yScale(this.data.data[d].processing)
          }
        })
        .attr('width', this.xScale.bandwidth())
        .attr('height', d => this.svgHeight - this.yScale(this.data.data[d].processing))

      select(this.$refs.processing)
        .selectAll('.processingText')
        .data(Object.keys(this.data.data))
        .text(d => this.data.data[d].processing ? this.data.data[d].processing : '')
        .attr('x', d => this.xScale(d) + this.xScale.bandwidth() / 2)
        .attr('y', d => this.yScale(this.data.data[d].processed) - (this.svgHeight - this.yScale(this.data.data[d].processing)) + 16)

      select(this.$refs.processed)
        .selectAll('rect')
        .data(Object.keys(this.data.data))
        .attr('x', d => this.xScale(d))
        .attr('y', d => this.yScale(this.data.data[d].processed))
        .attr('width', this.xScale.bandwidth())
        .attr('height', d => this.svgHeight - this.yScale(this.data.data[d].processed))

      select(this.$refs.processed)
        .selectAll('.processedText')
        .data(Object.keys(this.data.data))
        .text(d => this.data.data[d].processed ? this.data.data[d].processed : '')
        .attr('x', d => this.xScale(d) + this.xScale.bandwidth() / 2)
        .attr('y', d => this.yScale(this.data.data[d].processed) + 16)

      select(this.$refs.xAxis).call(this.xAxis)
      select(this.$refs.yAxis).call(this.yAxis)
    },
    addResizeListener () {
      window.addEventListener('resize', () => {
        const vm = this
        if (vm.windowWidth === window.innerWidth) return
        vm.redrawToggle = false
        setTimeout(() => {
          vm.redrawToggle = true
          vm.$nextTick(function () {
            this.svgWidth = Number(
              this.$refs.barChart.offsetWidth - this.svgPaddingX
            )
            this.animateLoad()
          })
        }, 0)
      })
    }
  },
  created () {},
  mounted () {
    this.$nextTick(function () {
      this.svgWidth = Number(
        this.$refs.barChart.offsetWidth - this.svgPaddingX
      )
      this.animateLoad()
      this.addResizeListener()
    })
  }
}
</script>

<style lang="scss" scoped>
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 1%;
  vertical-align: top;
  overflow: hidden;
  &::v-deep text {
    font-size: 14px;
  }
}
</style>
