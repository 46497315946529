<template>
  <div class="dashboard">
    <div class="container-fluid pt-4 pb-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__dashboard") }}</h2>
      <div class="mb-3">
        <span></span>
        {{ $t("__activeDevice") }}：
        <span>{{ onlineDeviceCount }}</span> /
        {{ $t("__allDevice") }}：
        <span>{{ allDeviceCount }}</span>
      </div>
      <div v-if="allAgencys" class="mb-3">
        <label class="typo__label mb-2">{{ $t('__chooseAnAgency') }}</label>
        <multiselect
          v-model="searchAgency"
          :options="allAgencys"
          :preserve-search="true"
          label="id"
          track-by="id"
          :placeholder="$t('__agencyName') + ' / ' + $t('__codeName')"
        >
          <template slot="singleLabel" slot-scope="props">
            {{ props.option.name + " / " + props.option.codename }}
          </template>
          <template slot="option" slot-scope="props">
            {{ props.option.name + " / " + props.option.codename }}
          </template>
        </multiselect>
      </div>

      <div class="row" v-if="data">
        <div class="col-lg-6">
          <div class="card mb-4">
            <div class="card-body">
              <h3 class="h5 fw-medium mb-4">{{ $t("__machineHealth") }}</h3>
              <BarChart
                type="device"
                v-if="data && data.device && data.device.count && (Object.keys(data.device.list).length > 0 || Object.keys(data.device.state).length > 0)"
                :keyName="[$t('__quantity'), $t('__codeName')]"
                :yKey="['02', '03', '04', '05', '06']"
                :svgPaddingX="svgPaddingX"
                :svgPaddingY="svgPaddingY"
                :data="filterDeviceData"
                :deviceAllCount="data.device.count"
              />
              <p v-else class="text-center">{{ $t("__notData") }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card mb-4">
            <div class="card-body">
              <h3 class="h5 fw-medium mb-4">
                {{ $t("__machineNetworkAbnormal") }}
              </h3>
              <BarChart
                type="network"
                :keyName="[$t('__quantity'), $t('__offlineAbbreviation')]"
                v-if="data && data.network && data.network.count"
                :yKey="networkYKey"
                :svgPaddingX="svgPaddingX"
                :svgPaddingY="svgPaddingY"
                :data="data.network"
                :deviceAllCount="data.device.count"
              />
              <p v-else class="text-center">
                {{ $t("__noMachineNetworkAbnormal") }}
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6">
          <div class="card mb-4">
            <div class="card-body">
              <h3 class="h5 fw-medium mb-4">{{ $t("__errorNotification") }}</h3>
              <div class="text-center mb-3">
                <div class="custom-tab-check d-inline-flex">
                  <div class="form-check">
                    <input
                      v-model="report_range"
                      class="form-check-input"
                      type="radio"
                      name="report_range"
                      :value="null"
                      id="now"
                    />
                    <label class="form-check-label fw-medium" for="now">{{
                      $t("__currentStatistics")
                    }}</label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="report_range"
                      class="form-check-input"
                      type="radio"
                      name="report_range"
                      value="week"
                      id="week"
                    />
                    <label class="form-check-label fw-medium" for="week">{{
                      $t("__statisticsForTheWeek")
                    }}</label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="report_range"
                      class="form-check-input"
                      type="radio"
                      name="report_range"
                      value="month"
                      id="month"
                    />
                    <label class="form-check-label fw-medium" for="month">{{
                      $t("__statisticsForTheMonth")
                    }}</label>
                  </div>
                </div>
              </div>
              <StackedBarChart
                type="network"
                :keyName="$t('__quantity')"
                v-if="data && data.report && data.report.count"
                :yKey="['1', '2', '3', '4', '5']"
                :svgPaddingX="svgPaddingX"
                :svgPaddingY="svgPaddingY"
                :data="data.report"
                :chartColor="chartColor.report"
              />
              <p v-else class="text-center">{{ $t("__notData") }}</p>
            </div>
          </div>
        </div> -->
      </div>
      <div v-else>
        {{ $t('__pleaseSelectAnAgency') }}
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/chart/BarChart.vue";
import StackedBarChart from "@/components/chart/StackedBarChart.vue";
import { mapState, mapMutations } from "vuex";
import { overview } from "@/http/api/overview.js";
import i18n from "@/lang/lang.js";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { agency } from "@/http/api/agency.js";

export default {
  name: "Dashboard",
  components: { BarChart, StackedBarChart, Multiselect },
  data() {
    return {
      data: null,
      report_range: null, // "month", "week"
      svgPaddingX: 90,
      svgPaddingY: 60,
      searchAgency: null,
      allAgencys: null,
      allDeviceCount: null,
      offlineDeviceCount: null,
      chartColor: {
        report: {
          processed: "#48EBDC",
          processing: "#FCAE70",
        },
      },
      networkYKey: ["3day", "1day", "12hour", "1hour", "30min", "10min"],
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    filterDeviceData() {
      // 排除 01 狀態
      var newData;
      if (this.data && this.data.device) {
        newData = this.data.device;
        if (Object.prototype.hasOwnProperty.call(newData.state, "01")) {
          delete newData.state["01"];
        }
      }
      return newData;
    },
    filterAgency() {
      const vm = this;
      const filterAgency = [];
      if (vm.searchAgency) {
        filterAgency.push(vm.searchAgency.id);
      }
      return filterAgency;
    },
    onlineDeviceCount() {
      const vm = this;
      if (vm.allDeviceCount === null || vm.offlineDeviceCount === null){
        return i18n.t("__loading")
      }
      return vm.allDeviceCount - vm.offlineDeviceCount
    },
  },
  watch: {
    report_range() {
      this.getOverview();
    },
    userInfo(data) {
      if (data) {
        this.initEvent ()
      }
    },
    searchAgency(data) {
      if (data) {
        this.getOverview();
      } else {
        this.data = null
      }
    },
  },
  methods: {
    ...mapMutations([
      "Loading",
      "Loaded",
      "AlertsInfo",
      "Alerted",
    ]),
    initEvent (){
      const vm = this;
      vm.getAllOverview();
      vm.getAllAgency();
    },
    getAllOverview () {
      const vm = this;
      overview.get(vm.token).then((res) => {
        if (res.status <= 201) {
          vm.allDeviceCount = res.data.data.device.count
          vm.offlineDeviceCount = res.data.data.network.count
        } else {
          vm.AlertsInfo({
            state: "error",
            title: i18n.t("__error"),
            info: res.data.errors.toString(),
          });
          vm.Alerted();
        }
        vm.Loaded();
      });
    },
    getOverview() {
      const vm = this;
      var config = Object.assign({}, vm.token);
      config.params = {};
      if (vm.report_range) {
        config.params.report_range = vm.report_range;
      }
      if (vm.filterAgency.length) {
        config.params.agency_ids = vm.filterAgency;
      }

      vm.Loading();
      overview.get(config).then((res) => {
        if (res.status <= 201) {
          vm.data = res.data.data;
          vm.allDeviceCount = res.data.data.device.count
          vm.offlineDeviceCount = res.data.data.network.count
        } else {
          vm.AlertsInfo({
            state: "error",
            title: i18n.t("__error"),
            info: res.data.errors.toString(),
          });
          vm.Alerted();
        }
        vm.Loaded();
      });
    },
    getAllAgency() {
      const vm = this;
      agency.get("", this.token).then((res) => {
        if (res.status <= 201 && res.data.status === "success") {
          vm.allAgencys = res.data.data.filter(
            agency => {
              return agency.status === 1
            }
          );
        }
      });
    },
  },
  created() {},
  mounted() {
    const vm = this
    if (vm.userInfo) {
      vm.initEvent ()
    }
  },
};
</script>

<style  lang="scss">
.multiselect__option--highlight {
  background-color: $primary;
  &::after {
    background-color: $primary;
  }
}
</style>
